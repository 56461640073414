<template>
  <v-app>

    <auth-core-view />

  </v-app>
</template>

<script>
  export default {
    name: 'AuthIndex',

    components: {
      AuthCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
